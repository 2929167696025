import React, { useEffect, useRef } from "react";
import MenuButton from "./MenuButton";
import gsap from "gsap";
import { MenuButtonNames } from "../Global/GlobalVariables";

const HamburgerDrop = () => {
  const burger = useRef(null);
  const menuDiv = useRef(null);

  useEffect(() => {
    const [liElements] = [burger.current.children];
    const menu = menuDiv.current;

    gsap.set([liElements, menu], { opacity: 0 });

    const tl = gsap.timeline({ defaults: { ease: "power3.in" } });

    tl.fromTo(
      menu,
      { y: "-=30" },
      { duration: 0.4, y: "+=30", opacity: 1 }
    ).fromTo(
      [...liElements],
      { x: "-=50" },
      { duration: 0.4, x: "+=50", opacity: 1 }
    );
  });

  return (
    <>
      <div ref={menuDiv} className="dropdown">
        <ul ref={burger} className="dropdown">
          {MenuButtonNames.map((element, id) => (
            <li className="dropdown" key={element} id={`li${id}`}>
              <MenuButton
                href={element.href}
                className="dropdown"
                name={element.name}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default HamburgerDrop;
